
import http from "@/utils/request";

//  确认
export function detail(data) {
    return http({
        method: "get",
        url: "/custAssessNotice/editSum/"+data+'.nd',
        // data
    });
}
