import conformModal from "./../components/confirm";
import appealModal from "./../components/appealZD.vue";
import { detail } from "./api";
import { confirm, appeal } from "./../api.js";
import viewProcess from "./../components/viewProcess"
export default {
  components: { conformModal, appealModal,viewProcess },
  data() {
    return {
      visible: false,
      visibleAppeal: false,
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/assessmentNotice",
          name: "考核通知单列表",
          title: "考核通知单列表"
        },
        {
          path: "/assessmentNotice/detail",
          name: "",
          title: "考核通知单详情"
        }
      ],
      navList: [],
      pageLoadFlag: false,
      data: [],
      columns: [
        {
          title: "序号",
          key: 1,
          dataIndex: "xuhao",
          align: "center"

        },
        {
          title: "品类",
          key: 2,
          dataIndex: "shopTypeName",
          align: "center"
        },
        {
          title: "比例（%）",
          key: 3,
          align: "center",
          dataIndex: "proportion"
        },
        {
          title: "扣除金额(元)",
          key: 4,
          dataIndex: "deductionAmount",
          align: "center"
        },
        {
          title: "对应大写金额",
          key: 5,
          dataIndex: "capitalize",
          align: "center"
        }
      ],
      detail: {},
      price: 0, // 单价
      assessMoney: 0,
      differenceCount: 0,
      money: 0,
      noticeStatusName: "",
      type: "",
      status:'',
      visibleprocess:false,
      affirmuCust:'',
      affirmTimeStr:''
    };
  },
  mounted() {
    this.getdetailInfo();
    // if (this.$route.query.noticeStatusName) {
    //     this.noticeStatusName = this.$route.query.noticeStatusName
    // }
  },
  methods: {
    // 打开流程弹窗
    openLiucheng(rowId, row) {
      this.status =''
      this.visibleprocess = true
      this.status = this.detail.checkNode
    },
    // 转换时间
    formatDate(value) {
      if (typeof (value) == "undefined") {
        return "";
      } else {
        let date = new Date(parseInt(value));
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ("0" + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ("0" + d) : d;
        let h = date.getHours();
        h = h < 10 ? ("0" + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ("0" + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ("0" + s) : s;
        return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      }
    },
    goBack() {
      window.close()
    },
    // 获取详情
    getdetailInfo() {
      let data = this.$route.query.id;
      this.pageLoadFlag = true
      detail(data).then(res => {
        if(res.data.code == 0) {
          this.detail = res.data.data.noticeList?res.data.data.noticeList[0]:'';
          this.assessMoney = this.detail.actualAssessMoney;
          this.differenceCount = this.detail.actualAssessCount;
          this.type = this.detail.noticeTypeName;
          this.data = res.data.data.details;
          this.noticeStatusName = res.data.data.noticeStatus;
          this.affirmuCust = res.data.data.affirmuCust
          this.affirmTimeStr = res.data.data.affirmTimeStr

          if (res.data.data.details && res.data.data.details.length > 0) {
            this.data.forEach((item, index) => {
              item.xuhao = index + 1;
            });
          }
        } else {
          this.$message.warning('获取详情失败,',res.data.msg)
        }
        this.pageLoadFlag = false
      });

    },
    changeStatus() {
      this.visibleAppeal = true;
    },

    confirm1() {
      let data = this.$route.query.id;
      confirm(data).then(res => {
        if (res.data.type == "success") {
          this.$message.success("确认成功," + res.data.text);
          window.opener.location.reload();
          this.timer = setTimeout(()=>{  //设置延迟执行
            window.close()
          },2000);
        } else {
          this.$message.warning("确认失败," + res.data.text);
        }
        this.getdetailInfo();
      });
    },
    appealConfirm($event, value, value1, value2) {
      let dataArr = []
      dataArr.push({
        id:this.detail?this.detail.id:'',
        appealDecreaseCount: value,
        appealReason: value1,
        appealFile: value2.length > 0 ? (value2.map(item => item.id)).join(','): ''
      })
      let data = {
        id: this.$route.query.id,
        appealList:dataArr
      }

      appeal(data).then(res => {
        if (res.data.type == "success") {
          this.$message.success("申诉成功," + res.data.text);
          window.opener.location.reload();
          this.timer = setTimeout(()=>{  //设置延迟执行
            window.close()
          },2000);
        } else {
          this.$message.warning("申诉失败," + res.data.msg);

        }
        this.getdetailInfo()
      });
    }
  }
};